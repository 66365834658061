<template>
   <v-dialog v-model="dialog" width="700px">
      <template v-slot:activator="{ on, attrs }">
      <a 
        v-bind="attrs"
        v-on="on">
        {{$t('page.cart.tnc')}}
      </a>
      </template>
      <v-card>
        <v-card-title class="text-h5 tnc-header">
          {{$t('page.cart.tnc')}}
        </v-card-title>

        <v-card-title>
          <span class="title-dialog mt-title-general">{{$t('tnc.gen_term')}}</span>
        </v-card-title>

        <v-card-text class="content-dialog">
          <ol style="padding-left: 15px !important;">
            <li>{{$t('tnc.gen_1')}}</li>
            <li>{{$t('tnc.gen_2')}}</li>
            <li>{{$t('tnc.gen_3')}}</li>
            <li>{{$t('tnc.gen_4')}}</li>
          </ol>
        </v-card-text>

        <v-card-title>
          <span class="title-dialog mt-title">{{$t('tnc.purc_term')}}</span>
        </v-card-title>

        <v-card-text class="content-dialog">
          <ol style="padding-left: 15px !important;">
            <li>{{$t('tnc.purc_1')}}</li>
            <li>{{$t('tnc.purc_2')}}</li>
            <li>{{$t('tnc.purc_3')}}</li>
            <li>{{$t('tnc.purc_4')}}</li>
          </ol>  
        </v-card-text>  

        <v-card-title>
          <span class="title-dialog mt-title">{{$t('tnc.others')}}</span>
        </v-card-title>

        <v-card-text class="content-dialog">
          <ol style="padding-left: 15px !important;">
            <li>{{$t('tnc.other_1')}}</li>
            <li>{{$t('tnc.other_2')}}</li>
            <li>{{$t('tnc.other_3')}}</li>
            <li>{{$t('tnc.other_4')}}</li>
          </ol>  
        </v-card-text> 

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            {{$t('tnc.i_accept')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
};
</script>

<style lang="scss" scoped>
.title-dialog {
  font-size: 12px;
  text-transform: uppercase;
  color: #00a4b3;
  font-weight: bold;
}
.content-dialog {
  text-align: justify;
  font-size: 12px;
  line-height: 20px;
  margin-top: -3px;
  color: #1f3c87 !important;
}
.tnc-header{
  background-color: #00a4b3;
  justify-content: center;
  color: white;
}
.mt-title-general{
  margin-top:-5px;
}   
.mt-title{
  margin-top:-30px;
}
</style>
